<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-12-16 18:48:04
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-12-16 22:42:24
-->
<template>
  <div class="showmainbodyc">
    <iframe class="prism-player" :src="file" width="100%" height="100%"></iframe>
  </div>
</template>
<script>
export default {
  data () {
    return {
      file: ''
    }
  },
  created () {
    let sUserAgent = navigator.userAgent.toLowerCase()
    let bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
    let bIsMidp = sUserAgent.match(/midp/i) == 'midp'
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
    let bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
    let bIsAndroid = sUserAgent.match(/android/i) == 'android'
    let bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
    let bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      this.file = `${
        window.location.origin
      }/pdf/web/viewer.html?file=${this.$route.query.file}#page=1`
      // 跳转移动端页面
    } else {
      window.location.href = this.$route.query.file
      // 跳转pc端页面
    }
  }
}
</script>

<style lang="stylus">
  .showmainbodyc {
    height 100vh
    background #fff
    width: 100vw;
  }
  .close img {
    height: 0.9rem;
    width: 0.9rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  .showmainbodyc /deep/ iframe {
    height: 100%;
    width: 100%;
  }
</style>
